import { Locale } from './locale';
import { Currency, ProductPriceRegion } from './currency';
import { ContentBlockType } from './content';
import { PictureEncode } from '../../components/Picture';

export enum VideoKind {
	youtube = 'youtube'
}

export enum VendorType {
	dealer = 'dealer',
	store = 'store',
	showroom = 'showroom',
}

export enum ContentBlockColumnListItemLinkType {
	link = 'link',
	anchor = 'anchor',
}

export enum Align {
	left = 'left',
	center = 'center',
	right = 'right',
	justify = 'justify'
}


export enum DocumentIcon {
	green = 'green',
	img = 'img',
	lightGray = 'lightGray',
	pdf = 'pdf',
	tech = 'tech',
}

export enum InteractiveHeroItemLabelPosition {
	left = 'left',
	right = 'right',
	top = 'top',
	bottom = 'bottom',
}

export enum LinkType {
	internal = 'internal',
	external = 'external',
	exact = 'exact'
}

export enum OrderDirection {
	asc = 'asc',
	desc = 'desc',
}

export enum ProductImpregnation {
	IN = 'IN',
	TOP = 'TOP',
	PCT = 'PCT',
	N = 'N',
}

export enum ProductUnit {
	sqm = 'sqm',
	item = 'item',
	ton = 'ton',
	bag = 'bag',
}

export enum SocialLinkType {
	instagram = 'instagram',
	facebook = 'facebook',
	linkedin = 'linkedin',
	youtube = 'youtube',
	twitter = 'twitter',
	pinterest = 'pinterest',
}

export enum SpecialPageType {
	homepage = 'homepage',
	help = 'help',
	blog = 'blog',
	contactSalesRepresentatives = 'contactSalesRepresentatives',
	contactInfo = 'contactInfo',
	contactMap = 'contactMap',
	shoppingList = 'shoppingList',
	shoppingListInquiry = 'shoppingListInquiry',
	shoppingListShare = 'shoppingListShare',
	downloads = 'downloads',
	career = 'career',
	searchResults = 'searchResults',
	representatives = 'representatives',
	swimmingPoolConfigurator = 'swimmingPoolConfigurator',
	staircaseAssemblyConfigurator = 'staircaseAssemblyConfigurator',
	staircaseStepsConfigurator = 'staircaseStepsConfigurator',
	fencePostCapsConfigurator = 'fencePostCapsConfigurator',
	pavementsConfigurator = 'pavementConfigurator',
	privacyNotice = 'privacyNotice'
}

export enum FulltextRecordKind {
	productLine = 'productLine',
	productSystem = 'productSystem',
	productCategory = 'productCategory',
	product = 'product',
	productComposition = 'productComposition',
	place = 'place',
	document = 'document',
	blogArticle = 'blogArticle',
	helpQuestion = 'helpQuestion'
}


export type UUID = string;

export type LinkableSimpleFragment = {
	url: string
}

export type LocaleSimpleFragment = {
	locale: {code: Locale},
	active?: boolean
}

export type FulltextHitFragment = {
	id: UUID,
	kind: FulltextRecordKind,
	link: LinkFragment,
	locales: (LocaleSimpleFragment & {
		title: string,
		subtitle: string
	})[]
};

export type CountryFragment = {
	code: string
};

export type VideoFragment = {
	id: UUID,
	kind: VideoKind,
	image: ImageFragment,
	locales: (LocaleSimpleFragment & {
		title: string,
		id: string,
		link: LinkableFragment,
		seo: SeoFragment
	})[]
}

export type ImageFragment = {
	internalUrl?: string,
	previewUrl?: string, 
	external?: boolean,
	intrinsicHeight?: number,
	intrinsicWidth?: number,
	intrinsicEncode?: PictureEncode
}

export type Model3dFragment = {
	geometry: DocumentFragment,
	texture: ImageFragment | null
}

export type SeoFragment = {
	description: string | undefined,
	title: string,
	titlePostfix?: string,
	keywords?: string | string[],
	ogTitle: string | undefined,
	ogDescription: string | undefined,
	ogImage: ImageFragment | undefined
}

export type ProductRackFragment = {
	id: UUID,
	code: string,
	image: ImageFragment | undefined,
	vendorCount: number,
	variants: ProductVariantFragment[],
	locales: (LocaleSimpleFragment & {
		title: string
	})[]
}

export type TopicLocaleFragment = LocaleSimpleFragment & 
{
	title: string
}

export type TopicFragment = {
	id: UUID,
	locales: TopicLocaleFragment[]
}

export type ArticleCategoryFragment = 
{
	id: UUID,
	name: string,
	link: {url: string},
	topics: TopicFragment[],
	seo: SeoFragment
}

export type ArticleLocaleFragment = LocaleSimpleFragment & {
	lead: string,
	title: string,
	link: LinkableSimpleFragment,
	content?: ContentFragment
}

export type ArticleFragment = {
	id: UUID,
	publishedAt: string,
	tileImage: ImageFragment | undefined,
	topics: TopicFragment[],
	locales: ArticleLocaleFragment[]
};

export type LinkableFragment = {
	url: string,
	blogArticle: {id: UUID},
	blogCategory: {id: UUID},
	page: {id: UUID, specialPage: SpecialPageType},
	product: {id: UUID},
	productCategory: {id: UUID},
	productUsage: {id: UUID},
	referenceCategory: {id: UUID},
	place: {id: UUID},
	helpQuestion: {id: UUID},
	redirect: {id: UUID, target: LinkableFragment},

}

export type LinkFragment = {
	type: LinkType,
	externalLink: string,
	internalLink: LinkableFragment,
	internalLinkQuery: {[prop: string] : string}
}

export type DocumentCategoryLocaleFragment = LocaleSimpleFragment & {name: string};

export type DocumentCategoryFragment = {
	id: UUID,
	order: number,
	locales: DocumentCategoryLocaleFragment[],
	icon: ImageFragment,
	parent: DocumentCategoryFragment | null
}

export type FileFragment = {
	id: UUID,
	url: string,
	type: string,
	name: string,
	size: number
};

export type DocumentLocaleFragment = LocaleSimpleFragment & {
	name: string,
	file: FileFragment
};

export type DocumentFragment = {
	id: UUID,
	categories: DocumentCategoryFragment[],
	ownIcon: ImageFragment,
	locales: DocumentLocaleFragment[]
}

export type DocumentListFragment = {
	items: {
		document: DocumentFragment
	}[]
}

export type ImageListFragment = {
	items: {
		image: ImageFragment
	}[]
}

export type RangeFragment = {
	min: number,
	max: number
}

export type ProductPriceFragment = {
	currency: Currency,
	cents: number,
	region: ProductPriceRegion
	unit?: ProductUnit
}

export type ProductPriceRangeFragment = {
	currency: Currency,
	minCents: number,
	maxCents: number,
	region: ProductPriceRegion
}

export type SamplerFragment = {
	id: UUID,
	image: ImageFragment,
	locales?: (LocaleSimpleFragment & {
		name: string
	})[]
}

export type ProductSurfaceLocaleFragment = LocaleSimpleFragment & 
{
	name: string
}

export type ProductSurfaceFragment = {
	id: UUID,
	locales: ProductSurfaceLocaleFragment[]
}

export type ProductSurfaceFragmentWithCount = ProductSurfaceFragment & {
	count: number
}

export type ProductColorLocaleFragment = LocaleSimpleFragment & 
{
	name: string
}

export type ProductColorFragment = {
	id: UUID,
	image: ImageFragment
	locales: ProductColorLocaleFragment[]
}

export type ProductColorFragmentWithCount = ProductColorFragment & {
	count: number
}

export type ShoppingListItemFragment = {
	variantId: UUID,
	count: number,
	product: ProductFragment
}

export type ProductVariantAttributesFragment = {
	quantityPerSqm: number,
	weightPerSqm: number,
	squareMeterPerPallet: number,
	quantityPerPallet: number,
	palletWeight: number,
	palletType: string,
	palletLayerQuantity: number,
	palletLayerSqm: number,
	impregnation: ProductImpregnation
}

export type ProductVariantFragment = {
	id: UUID,
	externalIdentifier: string,
	newVariant: boolean,
	discounted: boolean,
	prices: ProductPriceFragment[],
	images: ImageListFragment,
	sampler: SamplerFragment,
	surface: ProductSurfaceFragment,
	color: ProductColorFragment,
	attributes: ProductVariantAttributesFragment,
	locales: (LocaleSimpleFragment & {
		slug: string,
		title: string
	})[]
}

export type ProductLocaleFragment = LocaleSimpleFragment & {
	name: string,
	complementaryDimensions: string | undefined,
	altName: string | undefined,
	description: string,
	note: string,
	shortSize: string | undefined
	seo: SeoFragment
	videos: VideoFragment[]
}

export type ProductAttributesFragment = {
	width: number,
	height: number,
	length: number,
	unit: ProductUnit
}

export type CategoryListItemLocaleFragment = LocaleSimpleFragment & {
	name: string,
	lead: string
};

export type ContentCategoryListItemFragment = {
	id: UUID,
	link: LinkableFragment,
	tileImage: ImageFragment,
	locales: CategoryListItemLocaleFragment[]
}

export type ProductFragment = {
	id: UUID,
	newProduct: boolean,
	link: LinkableSimpleFragment,
	listingImage: ImageFragment,
	attributes: ProductAttributesFragment,
	locales: ProductLocaleFragment[],
	variants: ProductVariantFragment[]
}

export type CompositionLocaleFragment = LocaleSimpleFragment & {
	name: string,
	perex?: string,
	size: string
};

export type CompositionFragment = {
	id: UUID,
	listingImage: ImageFragment,
	images: ImageListFragment,
	locales: CompositionLocaleFragment[],
	items: {
		count: number,
		product: ProductFragment
	}[]
}

export type ContentBlockColumnListItemFragment = {
	id: UUID,
	primaryText: string,
	html: string,
	image: ImageFragment,
	buttonText: string,
	buttonLink: LinkFragment,
	linkType: ContentBlockColumnListItemLinkType,
	careerCategory: {id: UUID},
	titleAlignment: 'left-top' | 'right-top' | 'left-bottom' | 'right-bottom' | 'center',	
	useTitleBackground: boolean
}

export type ContentBlockColumnListFragment = {
	items: ContentBlockColumnListItemFragment[]
}

export type ContentBlockCategoryListFragment = {
	items: {category: ContentCategoryListItemFragment}[]
}

export type ContentBlockArticleListFragment = {
	items: {article: ArticleFragment}[]
}

export type ContentBlockProductListFragment = {
	items: {product: ProductFragment}[]
}

export type BannerFragment = {
	image: ImageFragment,
	link?: LinkFragment,
	items?: {
		xPosition: number,
		yPosition: number,
		label: string,
		labelPosition: InteractiveHeroItemLabelPosition,
		link: LinkFragment
	}[]
}

export type ContentBlockFragment = {
	type: ContentBlockType,
	primaryText: string,
	jsonContent: string,
	html: string,
	image: ImageFragment,
	gallery: ImageListFragment,
	noCrop: boolean,
	noReveal: boolean,
	columnList: ContentBlockColumnListFragment,
	categoryList: ContentBlockCategoryListFragment,
	articleList: ContentBlockArticleListFragment,
	documentList: DocumentListFragment,
	productList: ContentBlockProductListFragment,
	interactiveHero: {banners: BannerFragment[]},
	stretch: boolean,
	link: LinkFragment,
	videoList: VideoFragment[],
	requireCity: boolean,
	requireFormFields: boolean,
	enableFileAttachments: false | 'single' | 'multiple',
	formTargetEmail: string
}

export type ContentFragment = {
	blocks: ContentBlockFragment[]
}

export type PageLocaleFragment =  LocaleSimpleFragment & {
	title: string,
	content: ContentFragment,
	seo: SeoFragment
};

export type PageFragment = {
	locales: PageLocaleFragment[]
}

export type ProductTypeLocaleFragment = LocaleSimpleFragment & {
	name: string
};

export type ProductTypeConfiguratorFragment = {
	specialPage: SpecialPageType,
	data: ConfiguratorData
};

export type ProductTypeApplicableFilters = {
	attributes: boolean,
	price: boolean,
	color: boolean,
	length: boolean,
	height: boolean,
	width: boolean,
	surface: boolean,
	usage: boolean,
}

export type ProductTypeFilterSetting = {
	colors: UUID[] | null,
	surfaces: UUID[] | null,
	priceRange: ProductPriceRangeFragment | null,
	widthRange: RangeFragment | null,
	heightRange: RangeFragment | null,
	lengthRange: RangeFragment | null,
	attributes: {
		rideableByCar?: boolean,
		antislip?: boolean
	} | null
}

export type ProductCategoryFilterRanges = {
	colors: ProductColorFragmentWithCount[],
	surfaces: ProductSurfaceFragmentWithCount[],
	priceRange: ProductPriceRangeFragment[],
	widthRange: RangeFragment,
	heightRange: RangeFragment,
	lengthRange: RangeFragment
}

export type ProductTypeFragment = {
	id: UUID,
	tileImage: ImageFragment,
	link: LinkableFragment,
	locales: ProductTypeLocaleFragment[],
	configurator: ProductTypeConfiguratorFragment | null
}

export type ProductTypeSimpleFragment = {
	id: string,
	link: {url: string},
	locales: {
		locale: {code: string},
		name: string
	}[]
};

export type ProductTypeEvaluationFragment = {
	system: ProductTypeSimpleFragment | null,
	default: ProductTypeSimpleFragment | null,
	ancestors: ProductTypeSimpleFragment[],
	additional: ProductTypeSimpleFragment[]	
}

export type ProductTypeExtendedFragment = ProductTypeFragment & {
	kind: 'system' | 'productLine',
	parents: {
		id: UUID,
		name: string, 
		link: { url: string }
	}[],
	filters: ProductTypeApplicableFilters,
	introImage: ImageFragment,
	documents: DocumentListFragment,
	compositions: CompositionFragment[],
	references: ReferenceListFragment,
	locales: (ProductTypeLocaleFragment & {
		seo: SeoFragment,
		content: ContentFragment,
		videos: VideoFragment[]
	})[]
}

export type ContactCompanyFragment = {
	id: UUID,
	name: string,
	address: string,
	companyId: string,
	vatId: string,
	legalStatus: string,
	referenceNumber: string,
	phone: string,
	fax: string,
	email: string
}

export type ContactOfficeFragment = {
	id: UUID,
	name: string, 
	address: string,
	country: CountryFragment
}

export type ContactPersonLocaleFragment = LocaleSimpleFragment & {
	jobTitle: string
};

export type ContactPersonFragment = {
	id: UUID,
	name: string,
	phone: string,
	email: string,
	link: LinkFragment | undefined,
	locales: ContactPersonLocaleFragment[]
}

export type ContactDepartmentLocaleFragment = LocaleSimpleFragment & {
	name: string
};

export type ContactDepartmentFragment = {
	id: UUID,
	locales: ContactDepartmentLocaleFragment[],
	people: {
		person: ContactPersonFragment
	}[]
}

export type ContactRegionFragment = {
	code: string,
	name: string,
	salesRepresentative: ContactPersonFragment
}

export type VendorLocaleFragment = LocaleSimpleFragment & {
	title: string
	perex?: string
}

export type VendorFragment = {
	id: UUID,
	gpsLat: number | undefined,
	gpsLng: number | undefined,
	type: VendorType,
	address: string,
	city: string,
	zip: string,
	phone: string | undefined,
	email: string | undefined,
	link: LinkableSimpleFragment,
	locales: VendorLocaleFragment[]
}

export type NavigationItemFragment = {
	id: UUID,
	caption: string,
	highlighted: boolean,
	image: ImageFragment,
	link: LinkFragment
}

export type NavigationListFragment = {
	title: string,
	items: NavigationItemFragment[]
}

export type ProductNavigationItemFragment = {
	caption: string,
	image: ImageFragment,
	link: LinkFragment,
	primaryNavigation: NavigationListFragment,
	boxedNavigation: NavigationListFragment,
	imageNavigation: NavigationListFragment
}

export type HeaderNavigationItemFragment = NavigationItemFragment & {
	productNavigation: {
		items: ProductNavigationItemFragment[]
	}
}

export type SocialLinkFragment = {
	type: SocialLinkType,
	url: string
}

export type LayoutFragment = {
	footerAuthor: boolean,
	footerCopyright: boolean,
	footerBottomMenu: NavigationListFragment,
	socialLinks: SocialLinkFragment[],
	headerRightMenu: NavigationListFragment,
	footerMenuColumns: {
		title: string,
		navigation: NavigationListFragment
	}[],
	headerMainMenu: {
		title: string,
		items: HeaderNavigationItemFragment[]
	}
}

export type TranslationCatalogueFragment = {
	key: {identifier: string},
	value: string
}

export type TranslationDomainFragment = {
	cataloguesByIdentifier: {
		values: TranslationCatalogueFragment[],
		fallback: {values: TranslationCatalogueFragment[]}
	}
}

export type PageWithUrlFragment = {
	id: UUID,
	specialPage: SpecialPageType,
	link: LinkableSimpleFragment
}

export type OpeningHoursFragment = {
	title: string,
	value: string,
	options: {
		title: string,
		value: string
	}[]
}

export type HelpQuestionLocaleFragment = LocaleSimpleFragment & {
	title: string,
	content: ContentFragment,
	link: LinkableSimpleFragment
};

export type HelpQuestionFragment = {
	id: UUID,
	locales: HelpQuestionLocaleFragment[],
	topic: {name: string} | undefined
}

export type ReferenceLocaleFragment =  LocaleSimpleFragment & {
	title: string
};

export type ReferenceFragment = {
	id: UUID,
	listingImage: ImageFragment,
	image: ImageFragment,
	locales: ReferenceLocaleFragment[],
	products: ProductFragment[]
}

export type ReferenceListFragment = {
	items: {
		reference: ReferenceFragment
	}[]
}

export type RepresentativeLocaleFragment = LocaleSimpleFragment & {
	title: string,
	note: string
};

export type RepresentativeFragment = {
	id: UUID,
	images: ImageListFragment,
	locales: RepresentativeLocaleFragment[]
}

export type RepresentativeCategoryLocaleFragment = LocaleSimpleFragment & {
	name: string
} ;

export type RepresentativeCategoryFragment = {
    id: UUID,
    locales: RepresentativeCategoryLocaleFragment[],
	link: LinkableSimpleFragment
}

export type CareerPositionFragment = {
	id: UUID,
	title: string,
	content: ContentFragment,
	category: CareerCategoryFragment | null
}

export type CareerCategoryFragment = {
	id: UUID,
	name: string	
}

export type CreateInquiryInputFragment = {
	contact: {
		email: string,
		phone: string,
		name: string,
		place: string
	},
	note: string,
	items: {
		productVariant: {id: UUID},
		quantity: number
	}[]
}

export enum SwimmingPoolConfiguratorShapeKind
{
	middle = 'M',
	radius  = 'R',
	corner  = 'C',
	middleSpacer = 'S'
}

export enum SwimmingPoolConfiguratorShapeOrientation
{
	inner = 'inner',
	outer = 'outer'
}

export type SwimmingPoolConfiguratorShapeLocaleFragment = LocaleSimpleFragment & 
{
	name: string,
	perex: string
}

export type SwimmingPoolConfiguratorShapeFragment = 
{
	id: UUID,
	spec: string,
	image?: ImageFragment,
	locales: SwimmingPoolConfiguratorShapeLocaleFragment[]
}

export type SwimmingPoolConfiguratorVariantLocaleFragment = LocaleSimpleFragment & 
{
	name: string,
	perex: string
}

export type SwimmingPoolConfiguratorVariantProductFragment = 
{
	kind: SwimmingPoolConfiguratorShapeKind
	length: number  
	product: ProductFragment
	variantId: UUID
	thickness: number 
	overlap: number 
	count: number 
	orientation: SwimmingPoolConfiguratorShapeOrientation
	radius: number
}

export type SwimmingPoolConfiguratorVariantFragment =
{
	id: UUID,
	image?: ImageFragment
	locales: SwimmingPoolConfiguratorVariantLocaleFragment[],
	products: SwimmingPoolConfiguratorVariantProductFragment[]
	isDefault: boolean,
	productType: {
		id: UUID ,
		link: LinkableFragment
	} | null
}

export type SwimmingPoolConfiguratorDataFragment = 
{
	swimmingPoolShapes: SwimmingPoolConfiguratorShapeFragment[],
	swimmingPoolVariants: SwimmingPoolConfiguratorVariantFragment[]
}

export type StaircaseAssemblyConfiguratorStepFragment = 
{
	id: UUID,
	depth: number,
	minLength: number,
	maxLength: number,
	samplerIds: UUID[],
	prices: ProductPriceFragment[],
	locales: (LocaleSimpleFragment & {
		name: string
	})[]
}


export type StaircaseAssemblyConfiguratorStringerFragment = 
{
	id: UUID,
	depths: number[],
	minHeight: number,
	maxHeightsByStepLengths: [number, number][],
	maxDepthsByStepLengths: [number, number][],
	spacing: number,
	pricesFixed: ProductPriceFragment[],
	pricesStep: ProductPriceFragment[],
	pricesAlt: ProductPriceFragment[],
	pricesAltMin: ProductPriceFragment[],
	locales: (LocaleSimpleFragment & {
		name: string
	})[]
}

export type StaircaseAssemblyConfiguratorSupportFragmentVariant = 
{
	minLength: number,
	prices: ProductPriceFragment[],
	pricesBase2: ProductPriceFragment[],
	pricesBase3: ProductPriceFragment[],
}

export type StaircaseAssemblyConfiguratorSupportFragment = 
{
	id: UUID,
	variants: StaircaseAssemblyConfiguratorSupportFragmentVariant[],
	locales: (LocaleSimpleFragment & {
		name: string
	})[]
}

export type StaircaseAssemblyConfiguratorLandingFragment = 
{
	id: UUID,
	minDepthsByStepDepths: [number, number][],
	maxDepth: number,
	height: number,
	minLength: number,
	maxLength: number,
	samplerIds: UUID[],
	overhangThreshold: number,
	pricesVolume: ProductPriceFragment[],
	pricesLength: ProductPriceFragment[],
	locales: (LocaleSimpleFragment & {
		name: string
	})[]
}

export type StaircaseAssemblyConfiguratorSamplerFragment = SamplerFragment &
{
	isDefault: boolean
}

export type StaircaseAssemblyConfiguratorDataFragment = {
	steps: StaircaseAssemblyConfiguratorStepFragment[],
	stringers: StaircaseAssemblyConfiguratorStringerFragment[],
	landings: StaircaseAssemblyConfiguratorLandingFragment[],
	supports: StaircaseAssemblyConfiguratorSupportFragment[],
	samplers: StaircaseAssemblyConfiguratorSamplerFragment[]
} 

export type StaircaseStepsConfiguratorStraightFragment = {
	id: UUID,
	minLength: number,
	maxLength: number,
	priceCorrection: number,
	product: ProductFragment
}

export type StaircaseStepsConfiguratorRadiusFragment = {
	id: UUID,
	radius: number,
	location: string,
	count: number,
	product: ProductFragment
}

export type StaircaseStepsConfiguratorCornerFragment = {
	id: UUID,
	product: ProductFragment
}

export type StaircaseStepsConfiguratorDataFragment = {
	straights: StaircaseStepsConfiguratorStraightFragment[],
	radii: StaircaseStepsConfiguratorRadiusFragment[],
	corners: StaircaseStepsConfiguratorCornerFragment[]
}

export type FencePostCapsConfiguratorDataSamplerOptionFragment = {
	sampler: SamplerFragment,
	minWidth: number,
	maxWidth: number,
	minDepth: number,
	maxDepth: number,
	heights: {
		height: number,
		prices: ProductPriceFragment[]
	}[]
}

export type FencePostCapsConfiguratorDataFragment = {
	options: FencePostCapsConfiguratorDataSamplerOptionFragment[]
}

export type ConfiguratorData = 	
	 StaircaseAssemblyConfiguratorDataFragment |
	 SwimmingPoolConfiguratorDataFragment | 
	 StaircaseStepsConfiguratorDataFragment | 
	 FencePostCapsConfiguratorDataFragment;