import Head from 'next/head'
import React from 'react'
import { SeoFragment } from '../src/model/fragments'
import { getConfig } from '../src/utils/config'

export interface SeoProps {
	seo: SeoFragment | undefined
}

export const Seo: React.FunctionComponent<SeoProps> = ({ seo }) => {
	const ogImage = seo?.ogImage || {
		previewUrl: '/og-image.jpg',
		intrinsicWidth: 1200,
		intrinsicHeight: 630,
	}

	let keywords = seo?.keywords ?? [];
	if(keywords && typeof keywords === 'string') {
		keywords = keywords.split(',').map(k => k.trim());
	}
	keywords = (keywords as string[]).filter((k,i) => keywords.indexOf(k) === i);

	let postfix = `${seo?.titlePostfix ?? ''} Presbeton`.trim();
	return (
		<Head>
			<title>{seo?.title ? `${seo.title} | ` : ''}{postfix}</title>
			<meta name="robots" content={getConfig('robots').robots} />
			<meta name="description" content={seo?.description} />
			<meta property="og:title" content={seo?.ogTitle || seo?.title} />
			<meta property="og:image" content={ogImage.previewUrl} />
			{
				keywords && keywords.length > 0 && 
				<meta name="keywords" content={(keywords as string[]).join(',')} />
			}
			{ogImage.intrinsicWidth && (
				<meta property="og:image:width" content={`${ogImage.intrinsicWidth}`} />
			)}
			{ogImage.intrinsicHeight && (
				<meta property="og:image:height" content={`${ogImage.intrinsicHeight}`} />
			)}
			<meta
				property="og:description"
				content={seo?.ogDescription || seo?.description}
			/>
		</Head>
	)
}
