import cn from 'classnames'
import React from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import { CategoryImage } from './CategoryImage'
import { FlexiLink } from './FlexiLink'
import { SubMenuDesktopProps } from './SubMenuDesktop'

export interface SubMenuDetailProps {
	detail: SubMenuDesktopProps['items'][number]
}

export const SubMenuDetail: React.FunctionComponent<SubMenuDetailProps> = ({
	detail,
}) => {
	const translate = useTranslate()

	const primaryNavigation = detail.primaryNavigation?.items || []
	const boxedNavigation = detail.boxedNavigation?.items || []
	const imageNavigation = detail.imageNavigation?.items || []

	return (
		<div className="subMenuDetail">
			<div className="subMenuDetail-in">
				{primaryNavigation.length > 0 && (
					<section className="subMenuDetail-column view-primaryNavigation">
						<h6 className="subMenuDetail-title">
							{translate('header-menu-usage')}
						</h6>
						<ul className="subMenuDetail-list">
							{primaryNavigation.map((item) => (
								<li
									className={cn(
										'subMenuDetail-list-item',
										item.highlighted && 'view-highlighted',
									)}
									key={item.id}
								>
									<FlexiLink {...(item.link as any)}>
										<a className="subMenuDetail-list-link">{item.caption}</a>
									</FlexiLink>
								</li>
							))}
						</ul>
					</section>
				)}
				{boxedNavigation.length > 0 && (
					<section className="subMenuDetail-column view-contained view-boxedNavigation">
						<h6 className="subMenuDetail-title">
							{translate('header-menu-series')}
						</h6>
						<ul className="subMenuDetail-list">
							{boxedNavigation.map((item) => (
								<li className="subMenuDetail-list-item" key={item.id}>
									<FlexiLink {...(item.link as any)}>
										<a className="subMenuDetail-list-link">{item.caption}</a>
									</FlexiLink>
								</li>
							))}
						</ul>
					</section>
				)}
				{imageNavigation.length > 0 && (
					<section className="subMenuDetail-column view-imageNavigation">
						<h6 className="subMenuDetail-title">Typy</h6>
						<div className="subMenuDetail-images">
							{imageNavigation.map(
								(item) =>
									item.image && (
										<div className="subMenuDetail-images-item" key={item.id}>
											<CategoryImage
												FlexiLinkProps={item.link as any}
												image={item.image}
												title={item.caption}
											/>
										</div>
									),
							)}
						</div>
					</section>
				)}
			</div>
		</div>
	)
}
