import * as React from 'react'

function SvgMascotFooter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className='mascot' id="Vrstva_1" data-name="Vrstva 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.41 184.33">
      <g>
          <polygon points="121.54 149.39 79.23 149.39 63.1 165.52 63.1 182.11 105.41 182.11 121.54 165.98 121.54 149.39" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polyline points="63.11 165.54 105.39 165.54 105.39 182.09" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="105.39" y1="165.54" x2="121.54" y2="149.39" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="121.54 149.39 79.23 149.39 63.1 165.52 63.1 182.11 105.41 182.11 121.54 165.98 121.54 149.39" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polyline points="63.11 165.54 105.39 165.54 105.39 182.09" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="105.39" y1="165.54" x2="121.54" y2="149.39" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
      <g>
          <polygon points="198.37 149.39 156.06 149.39 139.93 165.52 139.93 182.11 182.24 182.11 198.37 165.98 198.37 149.39" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polyline points="139.95 165.54 182.22 165.54 182.22 182.09" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="182.22" y1="165.54" x2="198.37" y2="149.39" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="198.37 149.39 156.06 149.39 139.93 165.52 139.93 182.11 182.24 182.11 198.37 165.98 198.37 149.39" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polyline points="139.95 165.54 182.22 165.54 182.22 182.09" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="182.22" y1="165.54" x2="198.37" y2="149.39" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
      <g>
          <polygon points="101.47 2.22 73.66 30.02 73.66 113.44 101.47 141.24 184.89 141.24 212.69 113.44 212.69 30.02 184.89 2.22 101.47 2.22" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="88.07 15.68 60.26 43.48 60.26 126.9 88.07 154.7 171.48 154.7 199.29 126.9 199.29 43.48 171.48 15.68 88.07 15.68" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="199.23" y1="43.46" x2="212.66" y2="30.03" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="171.41" y1="15.64" x2="184.84" y2="2.21" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
      <g>
          <line x1="140.51" y1="65.96" x2="118.12" y2="65.9" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="78.2 55.98 122.58 55.98 122.58 70.78 105.67 87.6 95.06 87.6 78.2 70.78 78.2 55.98" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="136.08 55.99 180.46 55.97 180.46 70.77 163.57 87.6 152.95 87.6 136.08 70.79 136.08 55.99" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="54.13" y1="64.23" x2="85.54" y2="64.23" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="174.01" y1="64.23" x2="205.42" y2="64.23" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
      <g>
          <rect x="96.85" y="61.4" width="8.47" height="8.47" rx="4.23" ry="4.23" />
          <rect x="154.7" y="61.4" width="8.47" height="8.47" rx="4.23" ry="4.23" />
      </g>
      <polyline points="88.09 43.92 111.14 43.94 121.34 33.74" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      <line x1="145.45" y1="27.19" x2="163.95" y2="45.69" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      <g>
          <polygon points="163.63 103.1 163.63 105.3 144.3 124.63 115.25 124.63 95.92 105.3 95.92 103.1 163.63 103.1" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polygon points="106.88 103.1 163.63 103.1 163.63 105.3 157.91 111.03 114.81 111.03 106.88 103.1" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <g>
              <line x1="95.9" y1="96.67" x2="95.9" y2="112.22" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
              <line x1="163.65" y1="96.67" x2="163.65" y2="112.22" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          </g>
      </g>
      <line x1="112.79" y1="135.36" x2="146.76" y2="135.36" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      <g>
          <polygon points="191.87 110.49 199.77 118.39 189.28 128.88 197.04 136.64 206.19 145.78 218.59 145.78 237.19 127.18 237.19 114.78 218.59 96.17 206.19 96.17 191.87 110.49" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="196.98" y1="136.4" x2="211.6" y2="121.79" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="205" y1="144.15" x2="219.48" y2="129.67" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="199.86" y1="118.3" x2="208.99" y2="109.16" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
      <g>
          <polygon points="12.53 100.57 22.8 110.84 3.14 130.5 10.9 138.26 17.1 132.06 32.44 147.41 51.04 128.8 51.04 116.4 30.07 95.43 17.67 95.43 12.53 100.57" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <polyline points="11.5 137.63 26.7 122.44 42.1 137.85" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
          <line x1="24.86" y1="139.77" x2="34.44" y2="130.19" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "4.43px" }} />
      </g>
    </svg>
  )
}

export default SvgMascotFooter
