import cn from 'classnames'
import Link, { LinkProps } from 'next/link'
import React from 'react'
import { Arrow } from './Arrow'
import { ConditionalWrap } from './ConditionalWrap'

export enum ButtonView {
	default = 'default',
	tabs = 'tabs',
	outline = 'outline',
}

export interface ButtonProps {
	view?: ButtonView
	active?: boolean
	block?: boolean
	onClick?: (
		event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
	) => void
	onMouseEnter?: (
		event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>,
	) => void
	onMouseLeave?: (
		event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>,
	) => void
	icon?: React.ReactNode
	disabled?: boolean | string
	type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
	arrow?: boolean
	altText?: string
	linkProps?: LinkProps
	loading?: boolean
}

export const Button: React.FunctionComponent<ButtonProps> = ({
	view,
	active,
	children,
	onClick,
	onMouseEnter,
	onMouseLeave,
	icon,
	disabled,
	altText,
	type,
	block,
	arrow,
	loading,
	linkProps,
}) => {
	const isDisabled = Boolean(disabled)

	return (
		<div
			className={cn(
				'button',
				`view-${view || ButtonView.default}`,
				{
					'is-active': active,
					'view-block': block,
				},
				disabled && 'is-disabled',
				loading && 'is-loading'
			)}

			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<ConditionalWrap
				condition={isDisabled && typeof disabled === 'string'}
				wrap={(children) => (
					<span 
					className="warning-content">
						{children}
						{disabled}
					</span>
				)}
			>

				<ConditionalWrap
					condition={!isDisabled && !!altText && typeof altText === 'string'}
					wrap={(children) => (
						<span 
						className="alt-content">
							{children}
							<span className='alt'>{altText}</span>
						</span>
					)}
				>
					<ConditionalWrap
						condition={Boolean(linkProps)}
						wrap={(children) => (
							<Link {...linkProps!}>
								<a className="button-in" onClick={onClick}>
									{children}
								</a>
							</Link>
						)}
					>
						<ConditionalWrap
							condition={!Boolean(linkProps)}
							wrap={(children) => (
								<button
									className="button-in"
									onClick={onClick}
									disabled={isDisabled}
									type={type || 'button'}
								>
									{children}
								</button>
							)}
						>
							<>
								{icon && <span className="button-icon">{icon}</span>}
								<span className="button-content">
									<span>{children}</span>
									<span aria-hidden>{children}</span>
								</span>
								{arrow && (
									<span className="button-arrow">
										<Arrow />
									</span>
								)}
							</>
						</ConditionalWrap>
					</ConditionalWrap>
				</ConditionalWrap>
			</ConditionalWrap>
		</div>
	)
}
